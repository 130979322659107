.load_chapter_container {
  background-color: black;
  z-index: 40;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0%;
}

.load_chapter_container h1 {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  color: wheat;
}

.percent {
  position: absolute;
  bottom: calc(120px + 25%);
  left: 50%;
  transform: translate(-50%, -70%);
  color: white;
  font-size: 1.5rem;
  font-size: bold;
}

.progress_bar_container {
  height: 5px;
  width: 20vw;
  max-width: 500px;
  background-color: white;
  position: absolute;
  bottom: calc(120px + 25%);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 15px;
}

.progress_bar_container__bar {
  background-color: #ff0e75;;
  height: 5px;
  border-radius: 15px;
}

.continue {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
}

.continue button {
  background-color: transparent;
  color: white;
  border: none;
}

.continue::before {
  background-color: rgba(2, 5, 7, 0.5);
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  border-radius: 50%;
  animation: click_button 5s linear infinite;
}

@keyframes click_button {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.navigate {
  position: absolute;
  color: #fff;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.navigate p {
  text-shadow: 1px 1px 2px grey;
}

.navigate .arrow {
  animation: arrow_animation 2s linear infinite;
  width: 30px;
  height: 30px;
  filter: drop-shadow(1px 1px 3px grey);
}

@keyframes arrow_animation {
  0% {
    opacity: 1;
    transform: translateY(5px);
  }
  50% {
    opacity: 0.8;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(5px);
  }
}

.hidden {
  top: -150%;
  transition: all 3s;
}
