.menu {
  width: 100px;
  display: flex;
  flex-direction: column;
}

.menu_hover .menu_item .menu_item_content {
  display: var(--display-block);
  font-size: 0.6rem;
  letter-spacing: 1px;
  margin: 5px 30px;
}

.menu_hover .menu_item .social {
  display: var(--webkit-display-flex);
}

.menu_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.menu_item:hover .menu_item_content {
  color: var(--color-pink) !important;
}

.menu_item:hover .item {
  background-color: var(--color-pink) !important;
}

.menu_item .social svg:hover path {
  fill: var(--color-pink);
}

.item {
  width: 12px;
  height: 12px;
  background-color: var(--color-primary);
  margin: 5px 30px;
  display: flex;
}

.menu_item_content {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-transform: uppercase;
  font-size: 0px;
  margin: 0px 30px;
  transition: all 0.5s linear;
  color: var(--color-primary);
}

.social {
  left: 0;
  bottom: 0;
  flex-direction: var(--flex-direction-column);
  display: none;
  margin: -2px 0px 25px 0px;
}

.social svg {
  width: 10px;
  height: 10px;
}

.detail {
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 1px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: #fff;
}

.detail svg {
  height: 52px;
  margin-bottom: 17px !important;
  filter: drop-shadow(1px 1px 3px grey);
  z-index: 10;
}

.detail svg,
span {
  margin: 10px 0px;
}

.detail span {
  text-shadow: 1px 1px 3px rgba(169, 169, 169, 0.5);
}

.detail_raya {
  width: 2px;
  height: 50px;
  background-color: #fff;
  margin: 10px 0px;
}

@media (max-width: 1280px) {
  .item,
  .menu_item_content {
    margin: 5px !important;
  }
  .menu {
    margin-left: 25px;
  }
  .menu,
  .menu_item {
    flex-direction: row;
  }
  .menu_item_content {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
  }
  .detail {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
    min-width: max-content;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .detail_raya {
    width: 50px;
    height: 2px;
    margin: 0px 10px;
    transform: translateY(4px);
  }
  .social {
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: -2px 0px 0px 10px;
  }
}
