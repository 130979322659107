.container {
    background-image: url("/public/images/bg-03.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: var(--webkit-display-grid);
    grid-template-columns: var(--col-12);
    position: relative;
}

.container .container_body{
    grid-column: 4/10;
    place-self: center;
    text-align: center;
    height: 100vh;
    display: var(--webkit-display-grid);
    grid-template-rows: var(--col-12);
}

.container .container_body .first_child{
    grid-row: 5/6;
}

.container .container_body .body_title{
    color: var(--color-light);
    text-transform: uppercase;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0px 0px 10px 0px;
}

.container .container_body .body_text{
    color: var(--color-light);
    font-size: var(--font-size-15);
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0px;
}

.container .container_body .body_footer{
    color: var(--color-light);
    font-size: var(--font-size-17);
    font-weight: 200;
    grid-row: 10/12;
    place-self: center;
}

.container .container_body .body_footer p{
    display: var(--webkit-display-flex);
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 1px grey;
}

.container .container_body .body_footer p svg{
    width: 15px;
    margin: 0px 7px;
    filter:drop-shadow(1px 1px 1px grey);
}

.scrolling{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    -webkit-scroll-snap-type: y mandatory;
    background-color: var(--color-light);
}

.scrolling > div{
    scroll-snap-align: start;
    -webkit-scroll-snap-align: start;
}

.footer{
    margin: 10px auto;
    grid-row: 10 / 11;
}

.footer > div{
    display: var(--webkit-display-flex);
    flex-direction: var(--flex-direction-row);
    align-items: center;
    justify-content: space-around;
    color: var(--color-light);
    transition: all .3s ease-in-out;
    padding: 0px 20px;
    text-transform: uppercase;
    font-size: var(--font-size-13);
    position: var(--position-relative);
}

 .footer > div::before{
    position: var(--position-absolute);
    content: " ";
    background-image: url("../../assets/corchete-izquierda.svg");
    width: 21px;
    height: 72px;
    left: 0;
}

 .footer > div::after{
    position: var(--position-absolute);
    content: " ";
    background-image: url("../../assets/corchete-derecha.svg");
    transition: all .5s ease-in-out;
    width: 21px;
    height: 72px;
    right: 0;
}

 .footer > div span{
    margin-left: 10px;
}

 .footer div:hover{
    transform: scale(.9);
    cursor: var(--cursor-pointer);
}

 .footer > div .arrow{
    background-image: url('../../assets/flecha-blanca.svg');
    width: 60px;
    height: 60px;
    transition: all .3s ease-in-out;
}

.buttons {
    grid-row: 6 / 8;
}

.button_selected {
    background-color: var(--color-light) !important;
    color: var(--color-primary) !important;
}

.icons {
    right: 50px;
    bottom: 20px;
    width: 12%;
    justify-content: space-between;
}

.icons > img {
    width: 100%;
}

.legal_texts {
    grid-row: 12 / 13;
    margin: auto;
    justify-content: space-between;
}

.legal_texts > a {
    color: white;
    font-size: 12px;
    text-decoration: none;
}