.expand {
  top: 50vh;
  transform: translateY(-50%) !important;
  margin-left: 15px;
}

.menu {
  left: 2px;
  top: 30px;
}

.custom_text {
  color: var(--color-light) !important;
}

.full_screen_mobile {
  display: none;
  opacity: .9;
  width: 100vw;
  height: 100vh;
}

.full_screen_mobile h1 {
  color: wheat;
  font-size: 1rem;
}

.full_screen_mobile > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .menu {
    top: 20px;
    left: 10px;
  }

  .full_screen_mobile {
    display: flex !important;
  }
}