.navigate {
    grid-column: 4 / 10;
    place-self: end center;
    align-content: center;
    height: 100px;
    color: #fff;
    margin-bottom: 30px;
    z-index: 30;
}

.navigate p {
    text-shadow: 1px 1px 2px grey;
}

.navigate .arrow {
    animation: arrow_animation 2s linear infinite; 
    width: 30px;
    height: 30px;   
    filter:drop-shadow(1px 1px 3px grey);
}

@keyframes arrow_animation {
    0% { opacity: 1; transform: translateY(5px); }
    50% { opacity: .8; transform: translateY(-5px); }
    100% { opacity: 1; transform: translateY(5px); }
}

.navigate > p > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px 7px 0px 7px;
}

.navigate > p img {
    width: 12px;
    height: 12px;
}