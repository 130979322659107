.specialText {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: var(--color-light);
  width: 35%;
  animation: appear 2.5s linear alternate;
}

@media (max-width: 1280px) {
  .specialText {
    width: auto;
  }
}

.specialText_title {
  align-items: flex-end;
  margin-bottom: 25px;
  width: max-content;
}

.specialText_title h1 {
  margin: 0px;
  font-size: 2.5rem;
  align-items: flex-end;
  font-weight: bold;
  letter-spacing: 2px;
}

.specialText_title h1 span {
  margin-bottom: -10px;
  font-size: 5rem;
  display: table-cell;
  vertical-align: bottom;
}

.specialText_title svg {
  width: 1px;
  height: 25px;
  margin-left: 25px;
  writing-mode: horizontal-tb;
  transform: rotate(90deg);
}

.specialText_body {
  margin-left: 30px;
}

.specialText_body p {
  margin: 0px 0px 0px auto;
  width: 90%;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
