.expand_option {
    display: flex;
}

.text {
    color: var(--color-primary);
    font-size: var(--font-size-10);
    opacity: 0;
    transition: all 0.5s linear;
}

.container:hover .text {
    opacity: 1;
}

@media screen and (max-width: 1280px) {
    .expand_option {
      display: none;
    }
  }