.flex {
    display: var(--webkit-display-flex);
    display: -webkit-flex;
}
.flex-column {
    display: var(--webkit-display-flex);
    display: -webkit-flex;
    display: -moz-box;
    flex-direction: var(--flex-direction-column);
}
.flex-row {
    display: var(--webkit-display-flex);
    display: -webkit-flex;
    flex-direction: var(--flex-direction-row);
}
.block {
    display: var(--display-block);
}
.grid {
    display: var(--webkit-display-grid);
}
.relative {
    position: var(--position-relative);
}
.absolute{
    position: var(--position-absolute);
}
.fixed {
    position: var(--position-fixed);
}
.cursor-pointer {
    cursor: var(--cursor-pointer);
}
.fs-10 {
    font-size: var(--font-size-10);
}
.fs-12 {
    font-size: var(--font-size-12);
}
.fs-13 {
    font-size: var(--font-size-13);
}
.fs-15 {
    font-size: var(--font-size-15);
}
.fs-17 {
    font-size: var(--font-size-17);
}
.fs-20 {
    font-size: var(--font-size-20);
}
.fs-25{
    font-size: var(--font-size-25);
}
.fs-150 {
    font-size: var(--font-size-150);
}
.full-w {
    width: 100%;
}
.full-window-w {
    width: 100vw;
}
.full-h {
    height: 100%;
}
.full-window-h {
    height: 100vh;
}
.z-index-50{
    z-index: 50;
}
.align-center {
    align-items: var(--fadrines-center);
}
.content-center {
    justify-content: var(--fadrines-center);
}

/* Animations common styles */
.absolute-content{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: all .5s ease-out;
}
.content-disabled {
    opacity: 0;
    z-index: 0;
}
.content-disabled__non-animation {
    transition: none;
}
.content-active {
    opacity: 1;
    z-index: 10;
}
.content-active__animation {
    transition: none;
}

/* Animation for images and videos */
.animate{ 
    -moz-animation: zoom_image 8s linear forwards;
    -webkit-animation: zoom_image 8s linear forwards;
    animation: zoom_image 8s linear forwards;
}

@keyframes zoom_image {
    from { transform: scale(1) }
    to { transform: scale(1.15) }
}

@-moz-keyframes zoom_image {
    from { -moz-transform: scale(1) }
    to { -moz-transform: scale(1.15) }
}

@-webkit-keyframes zoom_image {
    from { -webkit-transform: scale(1) }
    to { -webkit-transform: scale(1.15) }
}

/* Button modal */
.button_modal {
    border: none;
    display: var(--webkit-display-flex);
    flex-direction: row;
    align-items: center;
}

.button_modal:hover {
    background-color: transparent;
}

.button_modal > div {
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.button_modal > div svg {
    width: 30px;
    height: 30px;
}

/* Logo Fadrines */
.logo {
    z-index: 10;
    grid-column: 12 / 13;
    place-self: end flex-end;
    display: flex;
    justify-content: flex-end;
    margin: 0px 25px 25px 0px;
    width: 100%;
}

.logo img {
    width: 25%;
    height: 25%;
}

.video-intro {
    opacity: 1;
    z-index: 10;
    position: relative;
}

.indicator-move-on{
    width: 100%;
    position: absolute;
    bottom: 33px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--color-light);
    font-size: var(--font-size-17);
    font-weight: 200;
    z-index: 50;
}

.indicator-move-on p{
    display: var(--webkit-display-flex);
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 1px grey;
    margin: 0;
}

.indicator-move-on > p > img {
    width: 40px !important;
    height: 26px !important;
}

.indicator-move-on p svg{
    width: 15px;
    margin: 0px 7px;
    filter:drop-shadow(1px 1px 1px grey);
}

.indicator-move-on a .arrow {
    animation: arrow_animation 2s linear infinite;
}

@keyframes arrow_animation {
    0% { opacity: 1; transform: translateY(5px); }
    50% { opacity: .8; transform: translateY(-5px); }
    100% { opacity: 1; transform: translateY(5px); }
}