.cookies_container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: max-content;
  z-index: 90;
  color: white;
  width: 100%;
  padding: 10px 0;
}

.cookies_container > div {
  z-index: inherit;
  position: relative;
  width: 80%;
  margin: auto;
}

.cookies_container > div > span {
  font-size: 11px;
  margin: 5px 0px;
}

.cookies_container button {
  min-width: 100px;
  padding: 3px 8px;
  font-size: 11px;
}

.cookies_container::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4e4e4e;
  opacity: 0.7;
  /* padding: 10px 0; */
}

.button_selected {
  background-color: var(--color-light) !important;
  color: var(--color-primary) !important;
}
