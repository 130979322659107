.accordion {
  justify-content: flex-end;
}

.item {
  grid-template-rows: 3fr 1fr;
  background-color: var(--color-light);
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.25) !important;
  width: 10%;
  transition: all 1.3s ease-in-out;
  overflow: hidden;
}

.item .number {
  place-self: end;
}

.item .container_indicator {
  grid-template-columns: 40% 60%;
}

.item .container_indicator_special {
  grid-template-columns: 30% 70%;
}

.item .container_indicator .text,
.item .container_indicator_special .text {
  place-self: center;
  z-index: 10;
  font-weight: 500;
  letter-spacing: 1px;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  font-size: 0.85rem;
}

.item .indicator {
  align-items: flex-end;
  margin-top: 2rem;
  place-self: flex-start;
}

.item .indicator h3 {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin-right: 25px;
  color: var(--color-primary);
  font-weight: lighter;
  font-size: var(--font-size-13);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.item .number {
  color: var(--color-primary);
  font-size: var(--font-size-150);
  font-weight: 100;
  margin: 0px;
  margin-bottom: -40px;
  padding: 0px;
  z-index: 10;
}

.item_hover {
  width: 60%;
}

.item_hover .indicator > h3 {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.item_hover .container_indicator .text,
.item_hover .container_indicator_special .text {
  margin-left: 2rem;
  margin-top: 50px;
  color: var(--color-primary);
  animation: color_white_blue 3s linear forwards;
}

@keyframes color_white_blue {
  0% {
    color: var(--color-light);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    color: var(--color-primary);
    opacity: 1;
  }
}

.item_hover .indicator > a {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.item_hover .numbers {
  transition: all 1.2s ease-in;
  z-index: 50;
}

.item_hover .image {
  z-index: 1;
  opacity: 1;
  transition: opacity 2s ease-in;
}

.image {
  width: auto;
  opacity: 0;
  right: 0px;
  z-index: -1;
}

.image .chapters_title {
  left: 60%;
  top: 15%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  overflow-wrap: break-word;
  width: 175px;
  animation: titles 1.5s linear forwards;
  font-size: 1rem;
  letter-spacing: 4px;
  font-weight: 500;
}

@keyframes titles {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image .chapters_title span {
  margin-bottom: 30px;
  font-size: 1.1rem;
}

.numbers {
  place-self: end;
  width: 100px;
  height: 143px;
  bottom: -29px;
  left: 0px;
}
