.container_indicator {
  right: 25px;
  top: 0;
  bottom: 0;
  height: auto;
  width: 50px;
  z-index: 10;
  align-items: flex-end;
}

.container_indicator .indicator {
  width: 10px;
  height: 2px;
  background-color: var(--color-light);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  margin: 1.5px;
}

.container_indicator .active {
  opacity: 1;
  width: 20px;
}

.arrow {
  padding: 20px 0;
  color: white;
}

.arrow_chevron {
  width: 16px !important;
  height: 16px !important;
}

@media (min-width: 1281px) {
  .arrow {
    display: none;
  }
}
