.container {
  position: var(--position-absolute);
  left: 10px;
  bottom: 20px;
  z-index: 50;
}

.vertical {
  position: fixed;
  z-index: 100000000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 40px;
  background-image: url('/public/images/bg-vertical.jpg');
  background-position: left;
  background-size: cover;
  color: white;
  font-size: 24px;
  text-align: center;
}

.vertical_subtext {
  color: #9e97b9;
}

.icon {
  width: 200px;
  padding-top: 100px;
}
