.images{
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
    animation: first 1s linear alternate;
    -moz-animation: first 1s linear alternate;
    -webkit-animation: first 1s linear alternate;
    background-color: black;
}

@keyframes first {
    0% { opacity: 0; }
    50% { opacity: 0;}
    100% { opacity: 1; }
}

@-webkit-keyframes first {
    0% { -webkit-opacity: 0; }
    50% { -webkit-opacity: 0;}
    100% { -webkit-opacity: 1; }
}

@-moz-keyframes first {
    0% { -moz-opacity: 0; }
    50% { -moz-opacity: 0;}
    100% { -moz-opacity: 1; }
}

.images img, video{
    width: 100%;
    height: 100%;
}


.dynamic_text{
    top: 50%;
    left: 30%;
    transform: translate(-30%, -50%);
}

.special_dynamic_text{
    background-color: var(--color-pink);
    text-transform: lowercase !important;
    font-weight: bold;
}

.special_dynamic_text_1{
    background-color: var(--color-pink);
    -moz-animation: appear_text 7s ease-in-out;
    -webkit-animation: appear_text 7s ease-in-out;
    animation: appear_text 7s ease-in-out;
}

@keyframes appear_text {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1; 
    }
}
@-webkit-keyframes appear_text {
    0% {
        -webkit-opacity: 0;
    }
    50% {
        -webkit-opacity: 0;
    }
    100% {
        -webkit-opacity: 1; 
    }
}
@-moz-keyframes appear_text {
    0% {
        -moz-opacity: 0;
    }
    50% {
        -moz-opacity: 0;
    }
    100% {
        -moz-opacity: 1; 
    }
}

.special_dynamic_text_2{
    left: 20%;
}

.logo {
    z-index: 40;
    grid-column: 12 / 13;
    place-self: end flex-end;
    display: flex;
    justify-content: flex-end;
    margin: 0px 25px 25px 0px;
    width: 100%;
}

.logo img {
    width: 25%;
    height: 25%;
}

.arrow {
    width: 30px !important;
    height: 30px !important;   
    filter:drop-shadow(1px 1px 3px grey);
    margin-top: 20px;
}

.background_white {
    background-color: var(--color-light);
}

.background_blue {
    background-color: var(--color-primary)
}

.transition {
    background-color: #fff;
}

.transitionActive{
    -moz-animation: container 1s linear alternate;
    -webkit-animation: container 1s linear alternate;
    animation: container 1s linear alternate;
}

@keyframes container {
    0% {
        opacity: 0;
        z-index: 0;
    }
    50% {
        opacity: 1;
        z-index: 100;
    }
    60% {
        opacity: 1;
        z-index: 100;
    }
    100% {
        opacity: 0; 
        z-index: 0;
    }
}

@-webkit-keyframes container {
    0% {
        -webkit-opacity: 0;
        z-index: 0;
    }
    50% {
        -webkit-opacity: 1;
        z-index: 100;
    }
    60% {
        -webkit-opacity: 1;
        z-index: 100;
    }
    100% {
        -webkit-opacity: 0;
        z-index: 0;
    }
}

@-moz-keyframes container {
    0% {
        -moz-opacity: 0;
        z-index: 0;
    }
    50% {
        -moz-opacity: 1;
        z-index: 100;
    }
    60% {
        -moz-opacity: 1;
        z-index: 100;
    }
    100% {
        -moz-opacity: 0; 
        z-index: 0;
    }
}

.special_footer{
    flex-direction: row !important;
}